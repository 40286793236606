import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useState, useEffect } from "react"
import { Link } from "gatsby"
import axios from "axios"

import Layout from "../components/layout"

const IndexPage = () => {
  let [expand, setExpand] = useState(false)
  let [remote, setRemote] = useState("walkin")
  let [specificRate, setSpecificRate] = useState(2.75)
  let [rates, setRates] = useState([])
  let [weight, setWeight] = useState(0)
  let [total, setTotal] = useState(0)
  let [error, setError] = useState("")

  useEffect(() => {
    const fetchRate = async () => {
      let { data: rates } = await axios.get(
        "https://texpress-api-arfum7vf6a-wl.a.run.app/api/ship_rates"
      )

      setRates(rates)
    }

    if (!rates.length) {
      fetchRate()
    }
  }, [rates.length])

  const calTotal = event => {
    event.preventDefault()
    setError("")

    if (!weight) {
      setError("Missing Weight.")
    } else if (!specificRate) {
      setError("Please Select City")
    }

    let totalWeight = parseFloat(weight)

    let sum = specificRate * totalWeight
    if (remote === "remote") {
      sum += totalWeight
    } else {
      sum -= 0.5 * totalWeight
    }
    setTotal(sum)
  }

  return (
    <Layout>
      <div className="grid grid-cols-1 lg:grid-cols-2 mt-10">
        <div className="w-full px-10 py-10 lg:w-full lg:px-0 lg:pl-10">
          <p className="text-5xl font-bold leading-relaxed w-4/5">
            Công Ty Vận Chuyển Hàng Hoá T Express
          </p>

          <div className="grid grid-cols-1 lg:grid-cols-2 mt-10 gap-20">
            <div>
              <h4 className="p-0 m-0 text-texpressblue">
                Local/Walkin Customer
              </h4>
              <p className="text-sm py-2 m-0">&nbsp;</p>
              <div className="flex">
                <div className="flex items-center justify-center rounded-full h-8 w-8 bg-white shadow-md">
                  <StaticImage
                    src="../images/item-marker.png"
                    alt="item-marker"
                  ></StaticImage>
                </div>
                <div className="text-md font-bold leading-loose ml-2">
                  Sài Gòn $2.75/lbs
                </div>
              </div>

              <div className="flex my-1">
                <div className="flex items-center justify-center rounded-full h-8 w-8 bg-white shadow-md">
                  <StaticImage
                    src="../images/item-marker.png"
                    alt="item-marker"
                  ></StaticImage>
                </div>
                <div className="text-md font-bold leading-loose ml-2">
                  Tỉnh gần $3.25/lbs
                </div>
              </div>

              <div className="flex my-1">
                <div className="flex items-center justify-center rounded-full h-8 w-8 bg-white shadow-md">
                  <StaticImage
                    src="../images/item-marker.png"
                    alt="item-marker"
                  ></StaticImage>
                </div>
                <div className="text-md font-bold leading-loose ml-2">
                  Tỉnh Xa $3.50/lbs
                </div>
              </div>
            </div>
            <div>
              <h4 className="p-0 m-0 text-texpressblue">Remote Customer</h4>
              <p className="py-2 m-0 text-sm">
                Khách ở tiểu bang ngoài California
              </p>

              <div className="flex">
                <div className="flex items-center justify-center rounded-full h-8 w-8 bg-white shadow-md">
                  <StaticImage
                    src="../images/item-marker.png"
                    alt="item-marker"
                  ></StaticImage>
                </div>
                <div className="text-md font-bold leading-loose ml-2">
                  Sài Gòn $3.00/lbs
                </div>
              </div>

              <div className="flex my-1">
                <div className="flex items-center justify-center rounded-full h-8 w-8 bg-white shadow-md">
                  <StaticImage
                    src="../images/item-marker.png"
                    alt="item-marker"
                  ></StaticImage>
                </div>
                <div className="text-md font-bold leading-loose ml-2">
                  Tỉnh gần $3.50/lbs
                </div>
              </div>

              <div className="flex my-1">
                <div className="flex items-center justify-center rounded-full h-8 w-8 bg-white shadow-md">
                  <StaticImage
                    src="../images/item-marker.png"
                    alt="item-marker"
                  ></StaticImage>
                </div>
                <div className="text-md font-bold leading-loose ml-2">
                  Tỉnh Xa $3.75/lbs
                </div>
              </div>
            </div>
          </div>

          {expand && (
            <div className="rounded-sm shadow-md bg-white mt-20 p-5">
              <form name="form" className="flex flex-col" onSubmit={calTotal}>
                <div className="flex flex-col w-3/5">
                  <label htmlFor="choose_from_by" className="font-bold text-sm">
                    Location - Chọn phương thức gửi:
                  </label>
                  <select
                    id="choose_from_by"
                    name="choose_from_by"
                    className="border-2 p-1 border-gray-200 radius-2 rounded-sm"
                    onChange={evt => setRemote(evt.target.value)}
                  >
                    <option value="walkin">Gửi hàng tại TExpress</option>
                    <option value="remote">Gửi từ các tiểu bang khác</option>
                  </select>
                </div>
                <div className="flex flex-col w-3/5 mt-2">
                  <label htmlFor="weight" className="font-bold text-sm">
                    Weight - Trọng Lượng:
                  </label>
                  <input
                    min={5}
                    id="weight"
                    type="number"
                    className="border-2 p-1 border-gray-200 radius-2 rounded-sm"
                    onChange={evt => setWeight(evt.target.value)}
                  />
                </div>
                <div className="flex flex-col w-3/5 mt-2">
                  <label htmlFor="tinh_thanh" className="font-bold text-sm">
                    City - Tình/Thành
                  </label>
                  <select
                    id="choose_from_by"
                    name="tinh_thanh"
                    className="border-2 p-1 border-gray-200 radius-2 rounded-sm"
                    onChange={evt =>
                      setSpecificRate(parseFloat(evt.target.value))
                    }
                  >
                    {rates.map(item => {
                      return (
                        <option key={item.id} value={item.default_rate}>
                          {item.location}
                        </option>
                      )
                    })}
                  </select>
                </div>

                {error && <span className="text-red-500">{error}</span>}

                {total > 1 && (
                  <p className="text-texpressblue mt-5">
                    Estimated Price: ${total}
                  </p>
                )}
                <button
                  type="submit"
                  className="rounded-sm mt-3 text-sm font-bold leading-none text-center bg-texpressblue text-white w-2/5 lg:w-1/5 h-8"
                >
                  Check Giá
                </button>
              </form>
            </div>
          )}

          <div className="flex pt-20 rounded-sm" style={{ display: "none" }}>
            <button
              className="rounded-sm px-5 text-sm font-bold tracking-wide leading-none text-center uppercase bg-texpressblue text-white py-3"
              onClick={() => setExpand(!expand)}
            >
              Tham Khảo Giá
            </button>
            <Link
              to="/contact"
              className="rounded-sm px-8 text-sm font-bold tracking-wide leading-none text-center uppercase border-texpressblue border-2 text-texpressblue py-3 ml-10"
            >
              LIÊN HỆ
            </Link>
          </div>
        </div>
        <div className="px-10 py-10 w-full text-center">
          <StaticImage
            className="w-full lg:w-4/5 sm:w-3/5"
            src="../images/cargo.png"
            alt="Cargo Img"
          ></StaticImage>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-10 px-10">
        <a href="https://portal.texpress.net/request_customer">
          <div
            className="shadow-md rouned-sm bg-gray-100 flex flex-col items-center justify-center py-8 px-5 hover:scale-105 transform transition duration-200 ease-in-out"
            style={{ height: 170 }}
          >
            <StaticImage
              className="w-6 mt-5"
              src="../images/barcode.png"
              alt="Barcode Img"
            ></StaticImage>
            <h4 className="mt-2">Create Customer Code</h4>
          </div>
        </a>
        <a href="https://portal.texpress.net/request_label/create">
          <div
            className="shadow-md rouned-sm bg-gray-100 flex flex-col items-center justify-center py-8 px-5 hover:scale-105 transform transition duration-200 ease-in-out"
            style={{ height: 170 }}
          >
            <StaticImage
              className="w-20 mt-5"
              src="../images/shipper.png"
              alt="Shipper Img"
            ></StaticImage>
            <h4 className="mt-2">Create Label UPS/FEDEX</h4>
          </div>
        </a>
        <a href="https://portal.texpress.net/tracking">
          <div
            className="shadow-md rouned-sm bg-gray-100 flex flex-col items-center justify-center py-8 px-5 hover:scale-105 transform transition duration-200 ease-in-out"
            style={{ height: 170 }}
          >
            <StaticImage
              className="w-6 mt-5"
              src="../images/question_mark.png"
              alt="Question Img"
            ></StaticImage>
            <h4 className="mt-2">Check Shipment</h4>
          </div>
        </a>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-10 mb-20 px-10">
        <div className="relative">
          <StaticImage
            className="h-80 rounded-sm shadow-md"
            src="../images/Plane.jpg"
            alt="Plane Img"
          ></StaticImage>
          <div className="rouned-b-sm absolute text-white bg-gradient-to-r from-gray-800 w-full text-center bottom-0 h-24 mb-1">
            <h3 className="mt-5">Chuyển Hàng Air</h3>
          </div>
        </div>
        <div className="relative">
          <StaticImage
            className="h-80 rounded-sm shadow-md"
            src="../images/Shelf.jpg"
            alt="Shelf Img"
          ></StaticImage>
          <div className="rouned-b-sm absolute text-white bg-gradient-to-r from-gray-800 w-full text-center bottom-0 h-24 mb-1">
            <h3 className="mt-5">Mua Hàng & Order</h3>
          </div>
        </div>
        <div className="relative">
          <StaticImage
            className="h-80 rounded-sm shadow-md"
            src="../images/Truck.jpg"
            alt="Truck Img"
          ></StaticImage>
          <div className="rounded-b-sm absolute text-white bg-gradient-to-r from-gray-800 w-full text-center bottom-0 h-24 mb-1">
            <h3 className="mt-5">Mua Hàng Nội Địa Mỹ</h3>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
